<script lang="ts" setup>
import { useDossierStore } from "@/common/store/dossier"
import { Ref, computed, nextTick, ref, watch } from "vue"
import { defang } from "@/common/lib/util"
import { iterateDossier } from "@/common/store/dossier/util"
import { useDossierData } from "./useDossierData"
import KnappLayout from "./layouts/knapp.vue"
import VielLayout from "./layouts/viel.vue"
import ShortDate from "@/common/components/ShortDate.vue"
import { storeToRefs } from "pinia"
import { usePrefsStore } from "@/common/store/prefs"
import { useRoute } from "vue-router"
const layoutOptions = {
  knapp: KnappLayout,
  viel: VielLayout,
}

const props = withDefaults(
  defineProps<{ dossierId: string; preview?: boolean }>(),
  { preview: false },
)

const dossierId: Ref<string> = computed(() => props.dossierId)
const dossierStore = useDossierStore()

const { developerMode } = storeToRefs(usePrefsStore())

const networkPositionsOut = ref(undefined as any)

const { dossierContents, dossierPreview, layout } = useDossierData(
  dossierId.value,
)

const route = useRoute()

const pageReady = computed(() => {
  return dossierStore.loading.percent === 100 &&
         dossierPreview.value &&
         dossierContents.value
})

watch(pageReady, (isReady) => {
  if (isReady && route.query.print === 'true') {
    const date = dossierPreview?.value?.meta?.created_at.toLocaleString("de", { dateStyle: "medium" })
    document.title = `${date}_dossier.direct `
    dossierStore.printMode = true
    nextTick(() => {
      setTimeout(() => {
      window.print()
      window.close()
    }, 3000)
    })
  }
})

window.addEventListener("beforeprint", async () => {
  dossierStore.printMode = true
})

window.addEventListener("afterprint", () => {
  dossierStore.printMode = false
})


</script>

<template>
    <div v-if="!dossierPreview">
      <v-navigation-drawer permanent app>
        <v-sheet
          class="d-flex flex-column justify-center align-center pb-4"
          color="transparent"
        >
          <v-skeleton-loader
            type="ossein"
            height="180"
            width="180"
            class="mt-4 elevation-2"
          />
          <v-skeleton-loader
            type="heading"
            class="text-h5 text-center my-2"
            width="200"
          />
          <v-skeleton-loader type="list-item-avatar@5" />
        </v-sheet>
      </v-navigation-drawer>
      <v-main
        class="ma-4"
        style="
          display: grid;
          place-items: center;
          position: absolute;
          min-height: 95%;
          width: 100%;
        "
      >
        <v-progress-circular
          color="primary"
          size="240"
          width="20"
          :model-value="dossierStore.loading.percent"
          :indeterminate="dossierStore.loading.percent === 0"
          >{{ dossierStore.loading.percent }}&thinsp;%</v-progress-circular
        >
      </v-main>
    </div>
    <div v-else>
      <component
        :is="layoutOptions[layout]"
        :dossierPreview="dossierPreview"
        :preview="preview"
        v-model:network-positions-out="networkPositionsOut"
      >
      </component>
      <v-main v-if="preview && developerMode" class="json-preview d-print-none">
        <pre style="border: inset 3px blue; margin: 3px; padding: 3px">{{
          JSON.stringify(defang(networkPositionsOut), undefined, 2)
        }}</pre>
        <pre>{{ JSON.stringify(defang(dossierPreview), undefined, 2) }}</pre>
        <hr />
        <!-- <pre>{{ JSON.stringify(dossierOverview, undefined, 2)}}</pre>
            <pre>{{ JSON.stringify(dossierContents, undefined, 2)}}</pre> -->
        <pre>{{
          JSON.stringify(
            defang(Array.from(iterateDossier(dossierContents!))),
            undefined,
            2,
          )
        }}</pre>
      </v-main>
      <div class="bottom-bar px-4 py-2 d-print-none">
        <h3 class="text-subtitle-2">
          <template v-if="preview"><b>Preview</b>: </template
          >{{ $t("dossier.dossier") }}

          {{ dossierPreview!.meta.code_name }}
          {{ $t("dossier.ofDate") }}
          <short-date
            location="bottom"
            :date="dossierPreview!.meta.created_at!"
          />
        </h3>
      </div>
    </div>
</template>
<style lang="scss">
.bottom-bar {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.d-none{
  display: none !important;
}
@media print {

  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  @page {
    size: A4 landscape;
    margin: 4mm 10mm 10mm 10mm ;
    -webkit-print-color-adjust: exact;

    @bottom-left {
        content: "dossier.direct";
    }
    @bottom-right {
        content: "Page " counter(page) " of " counter(pages);
    }
  }

  .preview-drawer{
    max-height: fit-content;
    .v-navigation-drawer__content{
      max-height: fit-content;
    }
  }

  .preview-page {
    display: flex;
    height: 100vh;

    .drawer-list{
        display: none !important;
    }
    .d-none{
        display: block !important;
      }
    .knapp-grid, .viel-grid {
      display: block !important;
      margin: 0 !important;
      padding-left:280px !important;

      .preview-card-block{
        margin-left:0 !important;
        margin-bottom: 20px !important;
        width:100%;
      }

      .bericht-block,.network-block , .entities-nodes-block, .social-media-nodes-block, .gallery-block, .person-list-block, .company-list-block,.news-press-block, .news-post-block {
        min-height: 100vh;
        break-inside: avoid;
      }
      .company-card, .person-card {
        break-inside: avoid;
      }

    }
  }
}

</style>
