<template>
  <div class="pa-2 d-flex flex-wrap" style="overflow: auto">

    <v-card class="ma-4 flex-0-0" v-if="companies.length > 1">
      <v-toolbar flat density="compact"
        ><template #title
          ><span class="text-subtitle-2">Unternehmen</span></template
        >
      </v-toolbar>
      <v-table v-if="companies.length > 1" density="compact">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="otherCompany in companies.slice().reverse().slice(0, -1)"
              :key="otherCompany"
            >
              {{ otherCompany }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in companies.slice(0, -1)" :key="company">
            <td>{{ company }}</td>
            <td
              v-for="otherCompany in companies
                .slice(companies.indexOf(company) + 1)
                .reverse()"
              :key="otherCompany"
            >
              <v-checkbox
                hide-details
                density="compact"
                color="primary"
                v-if="company !== otherCompany"
                v-model="model.company_company[company][otherCompany]"
              />
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-divider />
    <v-card
      class="ma-4 w-100"
      v-if="companies.length > 0 && persons.length > 0"
    >
      <v-toolbar flat density="compact">
        <template #title
          ><span class="text-subtitle-2">Unternehmen / Personen</span></template
        ></v-toolbar
      >
      <v-table
        class="pa-2"
        v-if="persons.length && companies.length"
        density="compact"
      >
        <thead>
          <tr>
            <th></th>
            <th v-for="(person, index) in persons" :key="index">
              {{ person }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(company, companyIndex) in companies" :key="companyIndex">
            <th>{{ company }}</th>
            <td
              class="pa-2"
              v-for="(person, personIndex) in persons"
              :key="companyIndex + personIndex"
            >
              <v-combobox
                style="width: 180px"
                clearable
                hide-details
                density="compact"
                placeholder="keine"
                :items="PersonCompanyRoles"
                v-model="model.person_company[person][company]"
              ></v-combobox>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-divider />

    <v-card class="ma-4 flex-0-0" v-if="persons.length > 1">
      <v-toolbar flat density="compact">
        <template #title
          ><span class="text-subtitle-2">Personen</span></template
        >
      </v-toolbar>
      <v-table v-if="persons.length > 1" density="compact">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="otherPerson in persons.slice().reverse().slice(0, -1)"
              :key="otherPerson"
            >
              {{ otherPerson }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="person in persons.slice(0, -1)" :key="person">
            <td>{{ person }}</td>
            <td
              v-for="otherPerson in persons
                .slice(persons.indexOf(person) + 1)
                .reverse()"
              :key="otherPerson"
            >
              <v-checkbox
                hide-details
                density="compact"
                color="primary"
                v-if="person !== otherPerson"
                v-model="model.person_person[person][otherPerson]"
              />
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </div>
  <v-dialog
        v-model="showDialog"
        @after-leave="emit('update:dialog', false)"
      >
      <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
                <div class="text-h5 text-medium-emphasis p-2">
                  Netzwerk
                </div>


              </v-card-title>

              <v-divider class="my-3"></v-divider>
     <div class="d-flex justify-center bg-white" min-width="800px"
     style="height: 600px">
      <main-graph-display v-if="Object.keys(dossierPreview.entities).length > 0"
            :pre-layouts="model.layout"
            :entities="dossierPreview.entities"
            @update:network-positions-out="layoutJson = JSON.stringify($event)"
          />

     </div>
     <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text="Close"
            variant="plain"
            @click="close"
          ></v-btn>

          <v-btn
            text="Save"
            variant="flat" color="primary"
            :disabled="!layoutJson"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
</template>

<script setup lang="ts">
import { computed, Ref, ref, watch } from "vue"
import {
  EntityDataEntityIdentifier,
  NetworkAnalysis,
  PersonCompanyRoles,
  DossierView,
  DossierContents,
  EventInfo,
  SocialMediaTypeX,
  ImageInfo,
  LocationInfo,
  ReportInfo,
  WebSnapshotInfo,
  ExternalWidgetEntry,
  MetricInfo,
  EntityDataInfo,
} from "@/common/store/dossier"
import MainGraphDisplay from "@/common/components/view/MainGraphDisplay.vue"
import { collectImages, extractEntityInformation } from "@/common/store/dossier/extract_data";

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    id: string
    dossierContents:DossierContents
    entities: Set<EntityDataEntityIdentifier>
    dialog: boolean
  }>(),
  { disabled: false },
)


const model = defineModel<NetworkAnalysis>({
  required: true,
})



const showDialog = ref<boolean>(props.dialog)

const dossierPreview: Ref<DossierView> = ref({
      meta : {} as DossierView["meta"],
      emails: [] as string[],
      events: [] as EventInfo[],
      social_media: [] as SocialMediaTypeX[],
      images: [] as ImageInfo[],
      locations: [] as LocationInfo[],
      reports: [] as ReportInfo[],
      web_snapshots: [] as WebSnapshotInfo[],
      widgets: [] as ExternalWidgetEntry[],
      metrics: [] as MetricInfo[],
      entities: {} as Record<EntityDataEntityIdentifier, EntityDataInfo>,
    })

const emit = defineEmits(["update:modelValue","update:dialog"])

const layoutJson = ref()

watch(
  () => props.dialog,
    (value) => {
    showDialog.value = value
  },

)

const persons = computed(() =>
  Array.from(props.entities)
    .filter((i) => i.startsWith("person/"))
    .map((i) => i.split("/").slice(1).join("/")),
)
const companies = computed(() =>
  Array.from(props.entities)
    .filter((i) => i.startsWith("company/"))
    .map((i) => i.split("/").slice(1).join("/")),
)

watch(
  persons,
  (persons) => {
    for (const person of persons) {
      if (!(person in model.value.person_person)) {
        model.value.person_person[person] = {}
      }
      if (!(person in model.value.person_company)) {
        model.value.person_company[person] = {}
      }
    }
  },
  {
    immediate: true,
  },
)

watch(
  companies,
  (companies) => {
    for (const company of companies) {
      if (!(company in model.value.company_company)) {
        model.value.company_company[company] = {}
      }
    }
  },
  {
    immediate: true,
  },
)

watch(
  model,
  (newData) => {
    emit("update:modelValue", newData)
  },
  {
    deep: true,
  },
)

watch(
  props.entities,
  () => {
    model.value.layout = undefined
    layoutJson.value = null
  },
  { deep: true },
)

watch(
  () => props.dossierContents,
   async (value) => {
    const clonedValue= JSON.parse(JSON.stringify(value))
    await collectImages(dossierPreview.value, clonedValue)
    extractEntityInformation(dossierPreview.value, clonedValue)
  },
  { deep: true, immediate: true },
)

const save = ()=>{
  model.value.layout = JSON.parse(layoutJson.value)
  showDialog.value = false
  layoutJson.value =  null
}

const close = ()=>{
  showDialog.value = false
  layoutJson.value = null
}


</script>
