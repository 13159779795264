// Auto translated via ChatGPT
export default {
  home: "Home",
  message: {
    loggingIn: "Logging In",
    loginProcess: {
      securing: "Establishing secure server connection...",
      completing: "Server connection secured",
      registering: "Registering user...",
      authenticating: "Authenticating user...",
      registeringFocusWait: "Registering user...",
      authenticatingFocusWait: "Authenticating user...",
      completed: "Login process completed",
      unauthenticated: "Login failed",
      cryptoError: "Security error occurred",
      otherError: "General error occurred",
      noauthError: "No login credentials",
    },
    exceed_file_size:
      "File @:{file} is larger than 255MiB and cannot be uploaded.",
    empty_fields_not_allowed: "Field must not be empty.",
    confirm_delete_title: "Delete dossier",
    confirm_delete_description:
      "Are you sure you want to delete the dossier {id} from {date} {title}? This operation cannot be undone.",
  },
  action: {
    agree: "Agree",
    cancel: "Disagree",
    reload: "Reload",
    retry: "Retry",
    pinEntry: "pin Entry",
    delete: "Delete",
    save: 'Save'
  },
  errorHelp: {
    cryptoError:
      "A secure connection to the database could not be established. " +
      "For security reasons, the connection attempt was completely aborted. " +
      "Possibly your login data or your secret link is incorrect (for example, copied incorrectly from an email). " +
      "Please contact your liaison.",
    otherError:
      "A general error occurred in the network communication during login. " +
      "Please try the process again by reloading the page or calling up your secret link again.",
    noauthError: "Contact the architect.",
  },
  error: {
    required: "Value required",
    invalidFormat: "Invalid format",
    invalidDate: "Invalid date",
    userNameTaken: "Name already taken",
  },
  pref: {
    developerMode: "Developer Mode",
  },
  validFormats: "Valid formats",
  dossier: {
    dossier: "dossier | dossiers",
    overview: "dossier overview",
    management: "dossier management",
    create: "Create @:dossier.dossier",
    createdAt: "created at",
    createdBy: "created by",
    chooseSuffix: "Choose letter:",
    share: "Share @:dossier.dossier",
    noLinks: "No links available",
    level: "Level",
    saving: "Saving @:dossier.dossier…",
    save: "Save @:dossier.dossier",
    unsavedChangesLastSave: "Unsaved changes present, last saved:",
    lastChange: "Last update:",
    ofDate: "of",
    conflictingEditDetected:
      "Attention: Someone else is currently editing this @:dossier.dossier, all your changes will be lost.",
    cannotSave: "Save not possible",
  },
  edit: {
    rowDelete: "Delete row",
    rowAdd: "Add row",
    fileDelete: "Delete file",
    fileAdd: "Add file",
    access: "Access",
    file: {
      preview: "Preview",
      label: "Label",
      source: "Source",
      file: "File",
      date: "Date",
    },
  },
  optional: "Optional",
  lastChangeAt: "last change at",
  stateAt: "status as of",
  superuser: "Superuser | Superusers",
  profiler: "Profiler | Profilers",
  analyst: "Analyst | Analysts",
  lastEdit: "last edited",
  share: "Share",
  open: "Open",
  cancel: "Cancel",
  doneBy: "by",
  assign: "Assign",
  assignProfiler: "Assign profiler",
  role: "Role",
  assignedTo: "assigned to",
  nobody: "Nobody",
  userSelect: {
    noRole: "No @:{role} assigned",
  },
  lockWorkstation: "Lock workstation",
  link: {
    link: "Link",
    create: "Create new link",
    isInactive: "is deactivated",
    deactivate: "End sharing",
    createInsecure: "Insecure Link",
    createInsecureHelp:
      "Need neither passkey nor PIN to access the link. Cannot be turned off again.",
  },
  deactivate: "deactivate",
  create: "create",
  select: "Select",
  user: {
    user: "User",
    name: "Name",
    type: "User type",
    management: "User management",
    overview: "User overview",
    create: "Create @:user.user",
    edit: "Edit @:user.user",
    reset: "Reset user",
    resetQuestion: "Reset user?",
    resetConfirm:
      "All workstations of the user will be removed and all encryption keys reset, then a new first workstation will be created.",
    resetDone: "The user has been reset.",
    createdDone: "The user has been created.",
    initialWorkstation:
      "The user can activate their first workstation with the following link. Please provide this link to the user:",
    linkOnce:
      "Attention: The link can only be used once to activate a single workstation (combination of device and browser). The user can create links for additional workstations themselves.",
  },
  logout: "Logout",
  block: {
    headline: "Headline",
    width: "Width",
    type: "Type of block",
    types: {
      Text: "Text (simple)",
      RichText: "Text",
      KeyValueTable: "Table (2 columns)",
      Chronology: "Chronology",
      FileCollection: "File collection",
      Gallery: "Gallery",
    },
    move: "Move block",
    delete: "Delete block",
  },
  help: {
    webauthn: {
      registration: {
        firstTime:
          "Since you are accessing this link for the first time, you must register for secure access.",
        localBiometry:
          "Depending on your device, you will now be prompted to secure access with a biometric feature (such as Face ID) or a PIN. These are not transferred to the server but remain on your device!",
        linkLocked:
          "After completing this registration, the secret link cannot be accessed by any other person.",
      },
      authentication: {
        localBiometry:
          "Depending on your device, you will now be prompted to confirm access with a biometric feature (such as Face ID) or a PIN. These are not transferred to the server but remain on your device!",
      },
      passkeyNote: "Technical background: We use passkeys to secure access.",
      clickHereForFocus: "Please click or touch here to continue the process.",
    },
    networkError: {
      hint: "The last action was unsuccessful. Should this happen repeatedly, please try refreshing the page. If you have made any changes, they might not have been saved.",
    },
  },
  closeError: "Close error message",
  networkError: "Error communicating with server",
  dateFormat: {
    displayPlaceholder: "DD.MM.YYYY",
    displayAllowed: [
      "DD.MM.YYYY",
      "DDMMYYYY",
      "DD,MM,YYYY",
      "DD/MM/YYYY",
      "DD-MM-YYYY",
    ],
  },
  meta: {
    persons: "persons",
    companies: "companies",
    title: "Title",
    customerNumber: "Kreditor",
  },
  eventsType: {
    socialMedia: "Soziale Medien",
    social: "SocialMedia-Account",
    post: "SocialMedia-Post",
    image: "Bild",
    document: "Dokument",
    website: "Webseite",
    press: "Presse",
    company_db: "Unternehmensdatenbanken",
    person_db: "Personendatenbanken",
    ereignisse: "Ereignisse",
    other: "Ereignis",
  },
  pressType: {
    press: "Presseberichte",
    company_db: "Unternehmensdatenbanken",
    person_db: "Personendatenbanken",
    image: "Image",
    document: "mdiFileDocument",
    account: "mdiAccount",
    website: "Web",
    other: "Web",
  },
  credential: {
    credential: "Passkey | Passkeys",
    credentialsOf: "Passkeys of",
    setName: "Set name",
    add: "Register passkey",
  },
  sidebarGroup: {
    person: "Person",
    company: "Company",
  },
  sections: {
    eingabedaten: "Eingabedaten",
    aufgaben: "Aufgaben",
    person: "person",
    unternehmen: "Unternehmen",
    verbindungsanalyse: "Verbindungsanalyse",
    archive: "Presseberichte",
    bericht: "Zusammenfassung",
    darkWeb: "Dark Web",
    machineLearningTools: "Machine Learning Tools",
    featuredFoto: "Featured Foto",
    kennzahlen: "Kennzahlen",
    googleTrends: "Google Trends",
    ereignisse: "Ereignisse",
  },
  fieldLabel :{
    whoIsCustomer: 'Wer ist der Kunde?',
    customerName: '(Sammelkunde wenn intern)',
    customerNumber: 'Kunden-Nr. aus lexoffiee',
    articleNumber: 'Artikel-Nr. aus',
  }
}
