<template>
  <div>
    <Teleport to="#app-bar-actions">
      <v-btn
        :prepend-icon="mdiFilePlus"
        variant="outlined"
        rounded="xs"
        v-if="canPerformAction(currentUser?.type,PERMISSIONS.CREATE_DOSSIER)"
      >
        <span class="hidden-sm-and-down">{{ $t("dossier.create") }}</span>
        <create-dossier-dialog
          v-model="showDossierCreateDialog"
          activator="parent"
          width="auto"
        />
      </v-btn>
    </Teleport>
    <v-data-table-virtual
      class="dossier-select-table"
      :headers="headers"
      :items="dossiersTableList"
      item-key="code_name"
    >
    <template v-slot:[`item.code_name`]="{ item }">
      <router-link :to="`dossiers/${item.id}`" class="text-link" >{{ item.code_name }}</router-link>
    </template>
    <template v-slot:[`item.meta`]="{ value, item  }">  <router-link :to="`dossiers/${item.id}`" class="text-link"  >{{ value?.title}}</router-link></template>
    <template v-slot:[`item.meta.customerNumber`]="{ value }"> {{ value}}</template>
    <template v-slot:[`item.level`]="{ item }">
        <v-select
          @click.stop
          :disabled="!canPerformAction(currentUser?.type,PERMISSIONS.UPDATE_LEVEL)"
          @update:model-value="
            (level: any) => dossierStore.updateDossierLevel(item, level)
          "
          class="mx-3"
          v-model="item.level"
          :items="[1, 2, 3]"
          density="compact"
          variant="outlined"
          hide-details
          style="max-width: 80px"
          v-if="canPerformAction(currentUser?.type,PERMISSIONS.UPDATE_LEVEL)"
        />
        <span v-else>{{ item.level }}</span>
    </template>

    <template
        v-slot:[`item.${role}`]="{ item }"
        v-for="role in ['profiler','analyst'] as UserDossierRole[]"
        :key="role"
      >
        <v-select
          v-if="canPerformAction(currentUser?.type, PERMISSIONS[`UPDATE_${role.toUpperCase()}`])"
          class="mx-3"
          style="max-width: 220px"
          :model-value="item.usersByRole[role]"
          :placeholder="$t('userSelect.noRole', { role })"
          @update:model-value="(u: any) => setUser(item, role, u)"
          persistent-placeholder
          clearable
          :items="availableUsersForRole[role]"
          item-title="display_name"
          item-value="url"
          variant="outlined"
          return-object
          persistent-clear
          hide-details
          @update:menu="fillUserList(item)"
          :prepend-inner-icon="item.usersByRole[role] ? mdiAccount : undefined"
          density="compact"
          @click.stop
        />
        <span v-else>{{ item.usersByRole[role]?.display_name }}</span>
      </template>

      <template v-slot:[`item.lastChange`]="{ item }">
        <short-date
          v-if="item.lastChange"
          location="bottom"
          :date="item.lastChange!"
          :short-style="{ dateStyle: 'medium', timeStyle: 'medium' }"
        />
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <short-date
          v-if="item.created_at"
          location="bottom"
          :date="item.created_at"
        />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div>
          <dossier-link-create-button :dossier="item" v-if="canPerformAction(currentUser?.type, PERMISSIONS.CREATE_LINK) ">
            <template v-slot:activator="{ props }">
              <v-btn
                class="mr-3"
                v-bind="props"
                :icon="mdiShare"
                density="compact"
                variant="plain"
                color="black"
              />
            </template>
          </dossier-link-create-button>
          <v-btn
            class="mr-3"
            :icon="mdiPrinter"
            target="_blank"
            density="compact"
            variant="plain"
            color="primary"
            :to="{
              name: 'dossierPreview',
              params: { dossierId: item.id },
              query: { print: 'true' }
            }"
          ></v-btn>
          <v-btn
            class="mr-3"
            :icon="mdiEye"
            target="_blank"
            density="compact"
            variant="plain"
            color="primary"
            :to="{
              name: 'dossierPreview',
              params: { dossierId: item.id },
            }"
          ></v-btn>
          <v-btn
          v-if="canPerformAction(currentUser?.type, PERMISSIONS.DELETE_DOSSIER)"
            @click.stop="showDeleteDialogAndSetItemToDelete(item)"
            :icon="mdiTrashCan"
            density="compact"
            variant="plain"
            color="error"
          ></v-btn>
        </div>
      </template>
    </v-data-table-virtual>
  </div>
  <v-dialog
    v-if="selectedDossier"
    v-model="showDialogDelete"
    max-width="600"
    persistent
  >
    <v-card
      :prepend-icon="mdiTrashCan"
      :text="
        $t('message.confirm_delete_description', {
          id: selectedDossier.code_name,
          date: selectedDossier.created_at.toLocaleString('de', {
            dateStyle: 'short',
          }),
          title: selectedDossier.meta?.title,
        })
      "
      :title="$t('message.confirm_delete_title')"
    >
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="showDialogDelete = false">
          {{ $t("action.cancel") }}
        </v-btn>
        <v-btn @click="deleteDossier(selectedDossier?.url)">
          {{ $t("action.delete") }}
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { Ref, computed, onMounted, ref } from "vue"
import { mdiAccount, mdiFilePlus, mdiPrinter, mdiShare } from "@mdi/js"
import { USER_ROLES, PERMISSIONS } from "@/common/constants/"
import {
  useDossierStore,
  DossierOverview,
  UserDossierRole,
} from "@/common/store/dossier"
import { storeToRefs } from "pinia"
import { User, useUserStore } from "@/common/store/user"
import CreateDossierDialog from "@/components/CreateDossierDialog.vue"
import { useI18n } from "vue-i18n"
import ShortDate from "@/common/components/ShortDate.vue"
import DossierLinkCreateButton from "@/components/DossierLinkCreateButton.vue"
import { mdiTrashCan, mdiEye } from "@mdi/js"
import { canPerformAction } from "@/common/store/user";
const { t } = useI18n()
const dossierStore = useDossierStore()
const { currentUser } = storeToRefs(useUserStore())
onMounted(dossierStore.fetchDossiers)
const userStore = useUserStore()
onMounted(userStore.fetchUsers)
const headers = [
  {
    title: "Dossier",
    key: "code_name",
  },
  { title: t("meta.title"), key: "meta" },
  { title: t("meta.customerNumber"), key: "meta.customerNumber" },
  { title: t("dossier.level"), key: "level" },
  { title: t("dossier.createdAt"), key: "created_at" },
  { title: t("profiler"), key: "profiler" },
  { title: t("analyst"), key: "analyst" },
  { title: t("dossier.lastChange"), key: "lastChange" },
  { title: "", key: "action" },
]

const showDialogDelete = ref(false)
const selectedDossier = ref()
const saveInProgress = ref(false)

const dossiersTableList = ref<DossierOverview[]>()


onMounted(async ()=>{
  await dossierStore.fetchDossiers()
  dossiersTableList.value = dossierStore.dossiersSorted
    .map(dossier => dossierStore.getDossierOverview(dossier.url).value as DossierOverview)
    .filter(Boolean)
})

const showDossierCreateDialog = ref(false)

const showDeleteDialogAndSetItemToDelete = async (dossier: DossierOverview) => {
  showDialogDelete.value = true
  selectedDossier.value = dossier
}

const deleteDossier = async (url: string) => {
  if (!url) return
  await dossierStore.deleteDossier(url)
  window.location.reload()
  showDialogDelete.value = false
}

const availableUsers: Ref<User[]> = ref([])
const availableProfilers: Ref<User[]> = computed(() => {
  return availableUsers.value.filter((user) =>
    [USER_ROLES.SUPERUSER, USER_ROLES.PROFILER].includes(user.type),
  )
})
const availableUsersForRole: Ref<Record<UserDossierRole, User[]>> = ref({
  profiler: availableProfilers,
  analyst: availableUsers,
})

async function fillUserList(dossier: DossierOverview) {
  const allUsers = userStore.users
  const assignedUserUrls = Object.values(dossier.usersByRole ?? {})
    .filter((u) => !!u)
    .map((u) => u.url)
  const possibleUsers = allUsers.filter(
    (u) => !assignedUserUrls.includes(u.url),
  )
  possibleUsers.sort((a, b) =>
    a.display_name < b.display_name
      ? -1
      : a.display_name > b.display_name
      ? 1
      : 0,
  )
  availableUsers.value = possibleUsers
}

async function setUser(
  dossier: DossierOverview,
  role: UserDossierRole,
  user: User | null,
) {
  saveInProgress.value = true
  try {
    await dossierStore.setUserRole(dossier, role, user)
  } finally {
    saveInProgress.value = false
  }
}


</script>

<style lang="scss">
.v-data-table.dossier-select-table table > thead > tr > th,
.v-data-table.dossier-select-table table tbody > tr > th {
  border-bottom: 3px solid #6e6e6e !important;
}
</style>
