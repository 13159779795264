<template>
<CardBlock >
        <v-container>
          <v-row>
            <v-col cols="4" class="d-flex flex-column">
              <v-sheet
                class="d-flex flex-column"
                color="transparent"
                style="grid-column: span 2"
              >
                <v-avatar
                  class="elevation-2"
                  color="grey-darken-1"
                  size="180"
                  cover
                >
                <template  v-if="entity.featuredImage">
                    <lazy-image
                      :image="entity.featuredImage"
                      v-if="!dossierStore.printMode"
                      cover
                    />
                    <v-img eager v-else :src="imageUrl" />
                  </template>
                  <v-icon v-else :icon="mdiWarehouse" size="128" />
                </v-avatar>
                <h3 class="text-h5">{{ entity.title }}</h3>
                <h4 class="text-subtitle-1" style="">
                  {{ entity.register_id }}
                </h4>
              </v-sheet>
              <SocialMedia
                noWidthOnCount
                elementSpacing="mr-2"
                alignment="start"
                :list="
                  view.social_media.filter((item) =>
                    item.entities.includes(entity.id),
                  )
                "
              >
              </SocialMedia>
            </v-col>
            <v-col cols="8">
              <sourced-value-table
                :items="[
                  ...upperTable,
                  ...informationTable,
                  ['Gegenstand', [{ value: entity.purpose }]],
                ]"
              />
            </v-col>
          </v-row>
        </v-container>
      </CardBlock>
</template>
<script setup lang="ts">
import CardBlock from "@/common/components/view/CardBlock.vue"
import SourcedValueTable from "@/common/components/view/SourcedValueTable.vue"
import SocialMedia from "@/common/components/view/SocialMedia.vue"
import LazyImage from "@/common/components/LazyImage.vue"
import { mdiWarehouse } from "@mdi/js"
import { useDossierStore } from "@/common/store/dossier"
import {
  DossierView,
  EntityDataInfo,
  SourcedValue,
} from "@/common/store/dossier"
import { computed } from "vue";
import { asyncComputed } from "@vueuse/core"

const dossierStore = useDossierStore()
const props = defineProps<{
  view: DossierView
  entity: EntityDataInfo & { type: "company" }
}>()

const upperTable = computed(() => {
  const retval = []
  if (props.entity.foundingDate) {
    retval.push(["Gegründet", [props.entity.foundingDate]])
  }
  for (const addr of props.entity.addresses || []) {
    retval.push([
      addr.value.label,
      [{ value: addr.value.address, source: addr.source }],
    ])
  }

  return retval as [string, SourcedValue<any>[]][]
})

const informationTable = computed(() => {
  return [...Object.entries(props.entity.otherInformation).sort()] as [
    string,
    SourcedValue<any>[],
  ][]
})

const imageUrl = asyncComputed(async () => {
  return props.entity.featuredImage?.lazyUrl()
}, undefined)

</script>
<style lang="">

</style>
