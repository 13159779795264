import {
  DossierView,
  EntityDataInfo,
  EventType,
  ExternalWidgetEntry,
  ImageInfo,
  LayoutType,
  ReportInfo,
  WebSnapshotInfo,
} from "@/common/store/dossier"
import { ExtendedLayouts } from "@/common/lib/types"
import {
  mdiMap,
  mdiNewspaper,
  mdiText,
  mdiTimeline,
  mdiViewGallery,
} from "@mdi/js"
import { ComputedRef, Ref, computed, ref } from "vue"
import { Dictionary, groupBy } from "lodash"
type GalleryEntry = {
  cols: number
  image: ImageInfo
}
export type NavInfo = {
  title: string
  target?: string | null
  icon?: string
  drawerOrder?: number
  openDrawer?: boolean
  openPhotoDrawer?: boolean
  expandMap?: boolean
  children?: NavInfo[]
}

const allowedNav = {
  viel: ["Berichte", "Fotos", "Medien", "Analysen"],
  knapp: ["Fotos"],
}

const calculateTotalYearsItems = (
  data: Record<number, Record<string, number>>,
) => {
  let total = 0

  for (const year in data) {
    if (Object.prototype.hasOwnProperty.call(data, year)) {
      const items = data[year]

      for (const itemType in items) {
        if (Object.prototype.hasOwnProperty.call(items, itemType)) {
          total += items[itemType]
        }
      }
    }
  }

  return total
}

export function useLayoutData(
  dossierPreview: DossierView | null,
  layout?: LayoutType,
): {
  dossierPreview: DossierView | null
  navInfo: Ref<NavInfo[]>
  featuredPhoto: Ref<ImageInfo | null>
  gallery: Ref<GalleryEntry[]>
  mainReport: Ref<ReportInfo | null>
  otherReports: Ref<ReportInfo[]>
  pressClippings: Ref<WebSnapshotInfo[]>
  widgets: Ref<ExternalWidgetEntry[]>
  yearEvents: Record<number, Record<string, number>>
  eventNumber: Ref<number>
  entities: Ref<Dictionary<EntityDataInfo[]>>
  predefinedNetworkLayout: Ref<ExtendedLayouts>
} {
  const navInfo = ref<NavInfo[]>([])
  const featuredPhoto = ref<ImageInfo | null>(null)
  const gallery = ref<GalleryEntry[]>([])
  const mainReport = ref<ReportInfo | null>(null)
  const otherReports = ref<ReportInfo[]>([])
  const yearEvents: Record<number, Record<string, number>> = {}
  const eventNumber = ref<number>(0)
  const pressClippings: ComputedRef<WebSnapshotInfo[]> = computed(
    () => dossierPreview?.web_snapshots ?? [],
  )

  const widgets: ComputedRef<ExternalWidgetEntry[]> = computed(
    () => dossierPreview?.widgets ?? [],
  )
  const predefinedNetworkLayout: ComputedRef<ExtendedLayouts> = computed(
    () => dossierPreview?.predefinedNetworkLayout ?? { nodes: {} },
  )

  const entities = computed<Dictionary<EntityDataInfo[]>>(
    () => groupBy(dossierPreview?.entities, (item) => item.type) ?? {},
  )

  function prepareLayout() {
    if (!dossierPreview) {
      return
    }
    const dp = dossierPreview

    navInfo.value.splice(0, navInfo.value.length)

    for (const image of dp.images) {
      if (image.thumbnailUrl && image.globalFeatured) {
        featuredPhoto.value = image
        break
      }
    }

    navInfo.value.push({
      title: "Berichte",
      target: null,
      icon: mdiText,
      openDrawer: true,
      children: [],
    })
    const berichte_nav = navInfo.value.find(
      (x) => x.title.toLowerCase() === "berichte",
    )

    navInfo.value.push({
      title: "Karte",
      target: null,
      icon: mdiMap,
      openDrawer: false,
      expandMap: true,
    })

    for (const report of dp.reports) {
      if (
        report.type == "general" &&
        !mainReport.value &&
        report.title &&
        ["zusammenfassung", "bericht"].includes(
          report.title.toLowerCase().trim(),
        )
      ) {
        mainReport.value = report
        continue
      }
      otherReports.value.push(report)
      if (report.title) {
        berichte_nav?.children?.push({
          title: report.title,
          drawerOrder: report.drawerOrder

        })
      }
    }

    if (
      dossierPreview.metrics.some((item) =>
        item.data.some((data) => data.value),
      )
    ) {
      dossierPreview.metrics.map((metric) => {
        if (!metric.title) return
        berichte_nav?.children?.push({
          title: metric.title,
        })
      })
    }

    if (dp.events) {
      navInfo.value.push({
        title: "Timeline",
        target: "timeline",
        icon: mdiTimeline,
        openDrawer: false,
      })
    }

    const otherImages = dp.images.filter((image) => !image.globalFeatured)
    if (otherImages.length) {
      let c = 0
      gallery.value = []
      for (const image of otherImages) {
        let aspect = Math.round(
          (3 * image.dimensions![0]) / image.dimensions![1],
        )
        if (aspect > 12) {
          aspect = 12
        }
        if (c + aspect > 12) {
          gallery.value[gallery.value.length - 1].cols += 12 - c
          c = 0
        }
        if (c + aspect >= 10) {
          aspect = 12 - c
        }
        gallery.value.push({
          cols: aspect,
          image,
        })
        c += aspect
        if (c >= 12) {
          c = 0
        }
      }
      navInfo.value.push({
        title: "Fotos",
        target: null,
        icon: mdiViewGallery,
        openPhotoDrawer: true,
      })
    }

    for (const event of dp.events) {
      const year = event.date.getFullYear()
      if (!event.date) return

      if (!yearEvents[year]) {
        yearEvents[year] = {}
      }

      let type: EventType = event.type || "other"
      if (event.type) {
        if (["social", "post"].includes(event.type)) type = "socialMedia"
        if (["website", "company_db", "person_db"].includes(event.type))
          type = "other"
      }
      if (!yearEvents[year][type]) {
        yearEvents[year][type] = 0
      }
      yearEvents[year][type] += 1
    }

    if (dp.web_snapshots.length) {
      navInfo.value.push({
        title: "HTML Archiv",
        target: "press_clippings",
        icon: mdiNewspaper,
        openDrawer: false,
      })
    }

    eventNumber.value = calculateTotalYearsItems(yearEvents)
  }
  prepareLayout()

  const allowed = allowedNav[layout || "viel"]
  navInfo.value = navInfo.value.filter((item) => allowed.includes(item.title))
  return {
    dossierPreview,
    navInfo,
    featuredPhoto,
    gallery,
    mainReport,
    otherReports,
    pressClippings,
    yearEvents,
    widgets,
    entities,
    predefinedNetworkLayout,
    eventNumber,
  }
}
