<template>
  <v-dialog
    :persistent="newDossierPending"
    @update:model-value="(v: Boolean) => $emit('update:modelValue', v)"
  >
    <v-card
      :loading="newDossierPending ? 'primary' : false"
      style="min-width: 30em"
    >
      <v-card-title class="mb-4">{{ $t("dossier.create") }}</v-card-title>
      <v-card-subtitle>
        {{ $t("dossier.chooseSuffix") }}
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="suffix"
          @input="sanitizeSuffix"
          maxlength="3"
          :rules="[!!suffix.length || $t('error.required')]"
        >
          <template #prepend-inner>
            <span class="text-no-wrap">
              {{ $t("dossier.dossier") }}&nbsp;{{
                namePrefix ? namePrefix : new Date().getFullYear() + "-XXXX-"
              }}
            </span>
          </template>
        </v-text-field>
      </v-card-text>
      <v-card-subtitle>
        {{ $t("dossier.level") }}
      </v-card-subtitle>
      <v-card-text>
        <v-select
          v-model="form.level"
          :disabled="newDossierPending"
          :items="[1, 2, 3]"
          variant="outlined"
        />
      </v-card-text>

      <v-card-subtitle>
        {{ $t("fieldLabel.whoIsCustomer") }}
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
        :label="$t('fieldLabel.customerName')"
          v-model="form.customerName"
          :disabled="newDossierPending"
          hide-details
        />
      </v-card-text>
      <v-card-text>
        <v-text-field
          :label="$t('fieldLabel.customerNumber')"
          v-model="form.customerNumber"
          :disabled="newDossierPending"
          hide-details
        />
      </v-card-text>
      <v-card-text>
      <v-radio-group v-model="form.articleNumber" inline>
        <template v-slot:label>
          <div>Wähle Artikelnummer für Rechnung</div>
        </template>
        <v-radio color="primary" v-for="item in articleNumberList" :key="item.value" :label="item.label" :value="item.value" />
      </v-radio-group>
    </v-card-text>
      <!-- FIXME Allow to assign other user directly
      <template v-if="currentUser && currentUser.type === 'superuser'">
        <v-card-subtitle
          >{{ $t("assignProfiler") }} ({{ $t("optional") }})</v-card-subtitle
        >
        <v-card-text>
          <v-select
            v-model="selectedUser"
            :disabled="newDossierPending"
            clearable
            :placeholder="$t('nobody')"
            :items="filterOutCurrentUser(users)"
            item-title="display_name"
            variant="outlined"
            return-object
          />
        </v-card-text>
      </template> -->
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          @click="$emit('update:modelValue', false)"
          :disabled="newDossierPending"
          rounded="xs"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!suffix.length || newDossierPending"
          @click="addDossier"
          rounded="xs"
        >
          {{ $t("dossier.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { Ref, ref, onMounted } from "vue"
import { useRouter } from "vue-router"
import { DossierLevel, useDossierStore } from "@/common/store/dossier"
import { asyncComputed } from "@vueuse/core"
import { User, useUserStore } from "@/common/store/user"
import { useApiStore } from "@/common/store/api"
import { storeToRefs } from "pinia"

import { articleNumberList } from '@/common/constants'

const emit = defineEmits(["created", "update:modelValue"])

const router = useRouter()
const dossierStore = useDossierStore()
const api = useApiStore()
const userStore = useUserStore()
const { users, currentUser } = storeToRefs(useUserStore())

const suffix: Ref<string> = ref("")

type Form = {
  code_name: string
  level: DossierLevel
  customerName: string
  customerNumber: string
  articleNumber: string
}
const form= ref<Form>({
  code_name: "",
  level: 1,
  customerName: "Sammelkunde",
  customerNumber: "10000",
  articleNumber: "D001",
 })

const selectedUser: Ref<User | undefined> = ref(undefined)

const newDossierPending = ref(false)

async function getNamePrefix() {
  const parts = await api.GET<{ year: string; number: string }>(
    "/dossier/next_number/",
  )
  return `${parts.year}-${parts.number}-`
}

const namePrefix = asyncComputed(async () => {
  if (!api.ready) {
    return null
  }
  return getNamePrefix()
}, null)

function sanitizeSuffix() {
  suffix.value = suffix.value.toUpperCase().replace(/[^A-Z]+/gi, "")
}

function filterOutCurrentUser(users: User[]) {
  return users.filter(
    (user) => !currentUser.value || user.url !== currentUser.value?.url,
  )
}

onMounted(async () => userStore.fetchUsers())

async function addDossier() {
  try {
    newDossierPending.value = true
    form.value.code_name = (await getNamePrefix()) + suffix.value
    const dossierId = await dossierStore.createEmptyDefaultDossier(
      form.value
    )

    newDossierPending.value = false
    emit("created", dossierId)
    // @ts-ignore
    emit("update:modelValue", false)
    selectedUser.value = undefined
    await router.push({
      name: "dossier",
      params: { dossierId: dossierId },
    })
  } catch (e) {
    console.error(e)
    newDossierPending.value = false
  }
}
</script>

<style scoped lang="scss"></style>
