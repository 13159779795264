import {
  ApiUrl,
  B64Data,
  EncryptedObj,
  EndorsedObj,
  EntityPublic,
  Hash,
  ObjectBase,
} from "@/common/lib/types"
import { ChapterRole, DossierMetaType } from "@/common/store/dossier"
import { ExtendedLayouts } from "@/common/lib/types"
export type UserDossierRole = "profiler" | "analyst"

export type UserMapping = {
  role: UserDossierRole
  user: ApiUrl
}

export type DossierInfoDTO = ObjectBase &
  EncryptedObj &
  EndorsedObj & {
    code_name: string
    manifest: B64Data
    users: UserMapping[]
    display: DisplayInfo
  }

export type PinState = "primed" | "active" | "disabled"

export type LinkDTO = ObjectBase &
  EntityPublic &
  EndorsedObj & {
    slug: string
    is_active: boolean
    number: number
    dossier: ApiUrl
    pin_state: PinState
    pin: string | null
  }

export type BlockInfoDTO = ObjectBase & {
  head: B64Data
}

export type DossierRetrieveData = {
  dossier: DossierInfoDTO
  blocks: BlockInfoDTO[]
  links: LinkDTO[]
  meta: DossierMetaType
}

export type ManifestBlockDTOv0 = {
  chapterIndex: number
  headHash: Hash
  id: string
  key: B64Data
  type: ChapterType
  role: ChapterRole
}

export type ManifestBlockDTOv1 = {
  id: string
  key: B64Data
  headHash: Hash
}

export type DossierLevel = 1 | 2 | 3

export const LayoutList = ["viel", "knapp"] as const

export type LayoutType = (typeof LayoutList)[number]
export enum LayoutDesign {
  Layout1 = "viel",
  Layout2 = "knapp",
}

export type DisplayInfo = {
  layout: LayoutType
}

export type DossierManifestDTOv0 = {
  version: undefined
  level: DossierLevel
  lastChange: string
  blocks: ManifestBlockDTOv0[]
}

export type DossierManifestDTOv1 = {
  version: 1
  level: DossierLevel
  lastChange: string
  blocks: ManifestBlockDTOv1[]
  display?: DisplayInfo
  metaBlockId?: string
}
export type DossierManifestDTO = DossierManifestDTOv0 | DossierManifestDTOv1
export type ManifestBlockDTO = DossierManifestDTO["blocks"][number]

export function isDossierManifestV1(
  manifest: DossierManifestDTO,
): manifest is DossierManifestDTOv1 {
  return manifest?.version == 1
}

export type DossierMetaDTO = {
  title: string,
  customerName?: string,
  customerNumber?: string,
  articleNumber?: string,
  company?: string[]
  person?: string[]
}

export type ChapterWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const LABELED_FILE_SECTION_TYPES = [
  "Gallery",
  "FileCollection",
  "FeaturedPhoto",
  "SavedWebsite",
]
export type LabeledFileSectionTypes =
  | "Gallery"
  | "FileCollection"
  | "FeaturedPhoto"
  | "SavedWebsite"

export const WITH_FILES_SECTION_TYPES = ["Archive"]
export type WithFilesSectionTypes = "Archive"

export const SMART_CHAPTERS = [
  "Chronology",
  "KpiBarChart",
  "SocialMedia",
  "FeaturedPhoto",
  "CompanyInformation",
  "EntityData",
  "Archive",
  "NetworkAnalysis",
] as const

type SmartChapterTuple = typeof SMART_CHAPTERS

export type SmartChapterTypes = SmartChapterTuple[number]

export type ChapterType =
  | "Text"
  | "RichText"
  | "EntityData"
  | "KeyValueTable"
  | "CommentsBlock"
  | SmartChapterTypes
  | LabeledFileSectionTypes
  | WithFilesSectionTypes
  | "NetworkAnalysis"
  | "ExternalWidgets"

// External Widget Type
export type ExternalWidgetEntry = {
  entities?: EntityDataEntityIdentifier[]
  title?: string
} & {
  type: "google_trends"
  code: string
}

export type ExternalWidgetsType = { widgets: ExternalWidgetEntry[] }

export type BlockType = ChapterType | "File" | "DossierMeta"

// key value source
export type KeyValueType = [string, string, string?]
export type SocialMediaType = [string, string, string, number?]
export type CompanyInformation = [string, string, string?]

export type ChronologyType = [string, string, string?, boolean?]

// RichTextType
export type RichTextType = { content: string }

// BarChartOption
export type BarChartOption = [number, string]

// CharBarType
export type KpiBarChartType = [string, number, BarChartOption[]?]

export type LabeledFileDTO = [string, string, string?, string?]
export type SectionWithFilesDTO = {
  entries: {
    files: string[]
  }[]
}

// Entity Data
export type EntityDataRow = {
  key: string
  value: string
  source?: string
}

export type EntityDataEntityTypes = "person" | "company"
export type EntityDataEntityIdentifier = `${EntityDataEntityTypes}/${string}`

export type EntityDataType = {
  entity: EntityDataEntityIdentifier
  entries: EntityDataRow[]
}

// Network Analysis
export const PersonCompanyRoles = [
  "angestellt",
  "GF",
  "Vorstand",
  "Beirat",
  "Aufsichtsrat",
  "Prokura",
  "beteiligt",
] as const

type PersonCompanyRolesTuple = typeof PersonCompanyRoles

export type PersonCompanyRoleType = PersonCompanyRolesTuple[number]

export type AdjacencyMatrix = {
  [A: string]: {
    [B: string]: boolean
  }
}

export type NetworkAnalysis = {
  person_person: AdjacencyMatrix
  company_company: AdjacencyMatrix
  person_company: {
    [person: string]: {
      [company: string]: PersonCompanyRoleType
    }
  }
  layout?: ExtendedLayouts
}

export type DateString =
  | `${number}-XX-XX`
  | `${number}-${number}-XX`
  | `${number}-${number}-${number}`

export type ArchiveEntryDTO = {
  id?: string
  title: string
  source?: string
  entities?: EntityDataEntityIdentifier[]
  date?: DateString
  important?: boolean
  files: string[]
  isNew?: boolean
  analyze?: boolean
  featured?: boolean
} & (
  | {
      type: "social"
      established?: DateString
      followers?: number
    }
  | {
      type: "post"
    }
  | { type: "press" }
  | { type: "image" }
  | { type: "document" }
  | { type: "website" }
  | { type: "company_db" }
  | { type: "person_db" }
)

export type ArchiveEntryType = ArchiveEntryDTO["type"]

export const ARCHIVE_TYPE_LABELS: { [t in ArchiveEntryType]: string } = {
  document: "Dokument",
  website: "Webseite",
  social: "Account (Social-Media)",
  post: "Post (Social-Media)",
  press: "Presse",
  image: "Bild",
  company_db: "Unternehmensdatenbank",
  person_db: "Personendatenbank",
}

export type ArchiveDTO = SectionWithFilesDTO & {
  entries: ArchiveEntryDTO[]
}
