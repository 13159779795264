import { ref } from "vue"
import { isEqual } from "lodash"

export function useGraph<NodeId extends string, N extends {}, E extends {}>() {
  type EdgeId = `${NodeId}__${NodeId}`

  type Node = {
    nodeId: NodeId
  } & N

  type Edge = {
    edgeId: EdgeId
    source: NodeId
    target: NodeId
  } & E

  const nodes = ref({} as Record<NodeId, Node>)
  const edges = ref({} as Record<EdgeId, Edge>)

  function addEdge(
    source: NodeId,
    target: NodeId,
    data: Partial<E> = {},
    directed = false,
  ) {
    if (!directed) {
      ;[source, target] = [source, target].sort()
    }
    ;(edges.value as Record<EdgeId, Edge>)[`${source}__${target}`] = {
      edgeId: `${source}__${target}`,
      source,
      target,
      ...data,
    } as Edge
  }

  function addNode(id: NodeId, data: Partial<N> = {}, warnDuplicate = false) {
    const newVal = { nodeId: id, ...data }
    const existing = (nodes.value as any)[id]
    if (existing && !isEqual(newVal, existing)) {
      console.log(`Warning, adding duplicate node ${id} with different values`)
    } else if (existing && warnDuplicate) {
      console.log(`Warning, overwriting existing node ${id}`)
    }
    ;(nodes.value as Record<NodeId, Node>)[id] = newVal as Node
  }


  function removeNode(id: NodeId | string) {
    delete (nodes.value as any)[id]
    for (const edgeId in edges.value) {
      if ((edges.value as any)[edgeId].source === id || (edges.value as any)[edgeId].target === id) {
        delete (edges.value as any)[edgeId]
      }
    }
  }

  function removeEdge(source: NodeId, target: NodeId, directed = false) {
    if (!directed) {
      ;[source, target] = [source, target].sort()
    }
    delete (edges.value as Record<EdgeId, Edge>)[`${source}__${target}`]
  }

  return {
    nodes,
    edges,

    addEdge,
    addNode,

    removeNode,
    removeEdge,
  }
}
