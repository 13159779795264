<template>
  <v-card-text>
    <div class="inner-grid pt-10">
      <PersonCard style="grid-column: span 4" :entity="entity"
      :view="view" />

      <CardBlock
        style="grid-column: span 4"
        v-if="filteredSocials.length"
        :title="`${entity.title}
        in
        sozialen Medien`"
      >
        <social-media-chart :model-value="filteredSocials" />
      </CardBlock>
      <div style="grid-column: span 4">
        <NewsBlock
          v-if="pressData.length > 0"
          maxHeight="100% !important"
          for-show
          :list="pressData"
          :with-drawer="false"
        />
      </div>
      <CardBlock
        title="Fotos"
        style="grid-column: span 4"
        v-if="imageList.length > 0"
      >
        <GalleryBlock :imageList="imageList" />
      </CardBlock>
    </div>
  </v-card-text>
</template>

<script setup lang="ts">
import {
  DossierView,
  EntityDataInfo,
} from "@/common/store/dossier"

import CardBlock from "@/common/components/view/CardBlock.vue"
import GalleryBlock from "@/common/components/view/GalleryBlock.vue"
import { computed } from "vue"
import SocialMediaChart from "@/common/components/view/SocialMediaChart.vue"
import NewsBlock from "./NewsBlock.vue"
import PersonCard from "./PersonCard.vue"

const props = defineProps<{
  view: DossierView
  entity: EntityDataInfo & { type: "person" }
}>()

const pressData = computed(() => {
  return props.view.web_snapshots.filter(
    (x) => x.entities?.includes(props.entity.id),
  )
})

const filteredSocials = computed(() =>
  props.view.social_media.filter(
    (item) =>
      item.entities.includes(props.entity.id) &&
      parseInt(`${item?.followers}` || "0") > 0,
  ),
)

const imageList = computed(() => {
  return props.view.images.filter(
    (item) =>
      !item.globalFeatured &&
      !item.featured &&
      item.entities.includes(props.entity.id),
  )
})
</script>

<style scoped lang="scss">
.inner-grid {
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ". .";
}
</style>
