<template>
   <CardBlock style="grid-column: span 4">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-sheet
                class="d-flex flex-column justify-center align-start"
                color="transparent"
              >
                <v-avatar
                  class="elevation-2"
                  color="grey-darken-1"
                  size="180"
                  cover
                >

                  <template  v-if="entity.featuredImage">
                    <lazy-image
                      :image="entity.featuredImage"
                      v-if="!dossierStore.printMode"
                      cover
                    />
                    <v-img eager v-else :src="imageUrl" />
                  </template>
                  <v-icon v-else :icon="mdiAccount" size="128" />
                </v-avatar>

                <h3 class="text-h5">{{ entity.title }}</h3>
                <h4
                  class="text-h6"
                  v-for="(alias, i) of entity.aliases"
                  :key="'alias' + i"
                  style="scale: 0.8; transform: translateX(-10px)"
                >
                  <span style="font-variant: small-caps">aka</span>
                  {{ alias.value }}
                </h4>
              </v-sheet>
              <SocialMedia
                noWidthOnCount
                elementSpacing="mr-2"
                alignment="start"
                :list="
                  view.social_media.filter((item) =>
                    item.entities.includes(entity.id),
                  )
                "
              >
                <v-menu open-on-hover v-if="entity.phone_numbers.length">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mr-2"
                      size="34"
                      rounded="xl"
                      :icon="mdiPhone"
                      v-bind="props"
                      variant="flat"
                      color="primary"
                    />
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(entry, i) of entity.phone_numbers"
                      :key="i"
                    >
                      {{ entry.key }}:
                      <a
                        :href="'tel:' + entry.value"
                        class="text-primary text-decoration-none"
                      >
                        {{ entry.value }}
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu open-on-hover v-if="entity.emails.length">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mr-2"
                      size="34"
                      rounded="xl"
                      :icon="mdiAt"
                      v-bind="props"
                      variant="flat"
                      color="primary"
                    />
                  </template>
                  <v-list>
                    <v-list-item v-for="(entry, i) of entity.emails" :key="i">
                      {{ entry.key }}:
                      <a
                        :href="'mailto:' + entry.value"
                        class="text-primary text-decoration-none"
                      >
                        {{ entry.value }}
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </SocialMedia>
            </v-col>
            <v-col cols="6">
              <sourced-value-table
                :items="informationTable"
                v-if="informationTable.length > 0"
            /></v-col>
          </v-row>
        </v-container>
      </CardBlock>
</template>
<script setup lang="ts">
import CardBlock from "@/common/components/view/CardBlock.vue"
import SourcedValueTable from "@/common/components/view/SourcedValueTable.vue"
import SocialMedia from "@/common/components/view/SocialMedia.vue"
import { mdiAccount, mdiAt, mdiPhone } from "@mdi/js"
import LazyImage from "@/common/components/LazyImage.vue"
import { useDossierStore } from "@/common/store/dossier"

const dossierStore = useDossierStore()
import {
  EntityDataInfo,
  SourcedValue,
  DossierView
} from "@/common/store/dossier"

import { computed } from "vue";
import { asyncComputed } from "@vueuse/core"
const props = defineProps<{
  view: DossierView
  entity: EntityDataInfo & { type: "person" }
}>()

const informationTable = computed(() => {
  const retval = []
  if (props.entity.birthDate) {
    retval.push(["Geburtsdatum", [props.entity.birthDate]])
  }
  for (const addr of props.entity.addresses || []) {
    retval.push([
      addr.value.label,
      [{ value: addr.value.address, source: addr.source }],
    ])
  }
  return [
    ...retval,
    ...Object.entries(props.entity.otherInformation).sort(),
  ] as [string, SourcedValue<any>[]][]
})

const imageUrl = asyncComputed(async () => {
  return props.entity.featuredImage?.lazyUrl()
}, undefined)
</script>
<style lang="">

</style>
