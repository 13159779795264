<script lang="ts" setup>
import {asyncComputed } from "@vueuse/core"
import { ImageInfo, SocialMediaTypeX } from "@/common/store/dossier/types/view"
import { mdiAccount } from "@mdi/js"
import LazyImage from "@/common/components/LazyImage.vue"
import SocialMedia from "@/common/components/view/SocialMedia.vue"
import { NavInfo } from "@/common/views/DossierView/useLayoutData"
import darkLogo from "@/common/assets/2023-02-27_dossier.direct_schriftlogo_dunkel.svg"
import ShortDate from "@/common/components/ShortDate.vue"
import { useURLParser } from "@/common/lib/util"
import { useDossierStore } from "@/common/store/dossier"

const dossierStore = useDossierStore()
const props = defineProps<{
  navList?: NavInfo[]
  featuredPhoto?: ImageInfo | null
  title?: string | undefined
  emails?: string[]
  socialMedia?: SocialMediaTypeX[]
  paragraph?: string | null
  createdDate?: Date
}>()

const emit = defineEmits(["set-app-drawer", "expand-map", "set-photo-drawer"])
const imageUrl = asyncComputed(async () => {
  return props.featuredPhoto?.lazyUrl()
}, undefined)
</script>

<template>
  <v-navigation-drawer permanent app class="preview-drawer">
    <v-sheet
      class="d-flex flex-column justify-center align-center pb-4"
      color="transparent"
    >
      <div class="align-self-start w-100 px-4 mb-8">
        <img
          class="mt-6 p-0"
          :src="darkLogo"
          alt="dossier.direct"
          height="28"
        />
        <p class="text-right text-accent7">
          Stand vom <short-date location="bottom" :date="createdDate!" />
        </p>
      </div>
      <v-avatar
        class="mt-4 elevation-2"
        color="grey-darken-1"
        size="180"
        :title="featuredPhoto?.title"
        cover
      >
       <template v-if="featuredPhoto">
        <lazy-image
                      :image="featuredPhoto"
                      v-if="!dossierStore.printMode"
                      cover
                    />
                    <v-img v-else :src="imageUrl" />
       </template>

        <v-icon v-else :icon="mdiAccount" class="w-100" :size="100"></v-icon>
      </v-avatar>
      <h3 class="text-h5 text-center my-2" v-if="title">
        {{ title }}
      </h3>
      <!-- <p
        class="pa-3 text-subtitle-2 text-center rich-content"
        v-html="
          paragraph!.length > 169
            ? paragraph!.substring(0, 170) + '..'
            : paragraph
        "
      ></p> -->
      <SocialMedia :list="socialMedia" class="d-print-none" />
      <ul class="text-left w-100 px-6 d-none d-print-block" style="list-style: none;">
        <li active v-for="sm in socialMedia" :key="sm.name" class="mb-1">
          <font-awesome-icon :icon="sm.icon" size="sm"  />
            @{{ useURLParser(sm.url).getUserName.value }}
        </li>
      </ul>

    </v-sheet>
    <v-list class="drawer-list">
      <template v-for="(navItem, i) of navList" :key="i">
        <v-list-group
          v-if="
            navItem.title.toLocaleLowerCase() === 'berichte' &&
            navItem.children &&
            navItem.children.length
          "
          class="d-flex flex-column"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              :prepend-icon="navItem.icon"
              :title="navItem.title"
            ></v-list-item>
          </template>
          <v-list-item
            v-for="({ title, target, drawerOrder }, i) in navItem.children"
            :style="`order:${drawerOrder}`"
            @click.stop="() => navItem.openDrawer && emit('set-app-drawer')"
            :key="i + title"
            :title="title"
            :value="title"
            :to="target ? '#' + target : undefined"
          ></v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          border
          variant="plain"
          color="black"
          :prepend-icon="navItem.icon"
          :title="navItem.title"
          :to="navItem.target ? '#' + navItem.target : undefined"
          @click.stop="
            () =>
              (navItem.openDrawer && emit('set-app-drawer')) ||
              (navItem.expandMap && emit('expand-map')) ||
              (navItem.openPhotoDrawer && emit('set-photo-drawer'))
          "
        >
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<style lang="scss">
.drawer-list {
  margin-top: 50px;
  .v-list-group__items{
  display: flex;
  flex-direction: column;
}
}

</style>
