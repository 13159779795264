import {
  CHRONOLOGY_TYPE_X_FIELDS,
  ChronologyTypeX,
  DossierContents,
  DossierView,
  EntityDataEntityIdentifier,
  EntityDataInfo,
  EntityDataRow,
  ImageInfo,
  KeyValueType,
  LabeledFile,
  LabeledFileSectionTypes,
  MetricInfo,
  ReportType,
  Section,
  SOCIAL_MEDIA_TYPE_X_FIELDS,
  SocialMediaTypeX,
  WebSnapshotInfo,
  YearOrDate,
} from "@/common/store/dossier/types"
import { iterateDossier } from "@/common/store/dossier/util"
import { until, useObjectUrl } from "@vueuse/core"
import {
  mdiAccount,
  mdiAccountBoxPlusOutline,
  mdiAccountFileText,
  mdiCalendar,
  mdiFileDocument,
  mdiImage,
  mdiMapMarker,
  mdiNewspaper,
  mdiTextAccount,
  mdiWarehouse,
  mdiWeb,
} from "@mdi/js"
import {
  approximateDate,
  arrayToObjectArray,
  DateStruct,
  parseDate,
  PLZ_RE,
  toIsoDate,
  useURLParser,
  withProtocol,
} from "@/common/lib/util"
import { lookupGermanPlz } from "@/common/lib/germanPlz"
import { escape, omit, pick } from "lodash"
import { getSocialMediaForDomain, lookupDomain } from "@/common/data/domainList"
import { useDossierStore } from "@/common/store/dossier/store"
import { imageFromUrl } from "@/common/lib/image"
import { _DEFAULT_KPI_BAR_CHART_LEVELS } from "@/common/store/dossier/default"

export async function collectImages(
  view: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier(contents, [
    "Gallery",
    "FeaturedPhoto",
  ])) {
    for (const entry of section.body as LabeledFile[]) {
      const image = {
        globalFeatured: section.meta.role === "FeaturedPhoto",
        featured: false,
        title: entry.label,
        source: entry.source,
        date: entry.date,
        entities: [],
      } as Partial<ImageInfo>

      if (
        entry.content.meta?.height &&
        entry.content.meta?.width &&
        entry.content.meta?.thumbnail
      ) {
        image.dimensions = [
          entry.content.meta?.width,
          entry.content.meta?.height,
        ]
        image.thumbnailUrl = entry.content.meta?.thumbnail
        image.lazyUrl = async () => {
          const file = await useDossierStore().loadRemoteFile(entry.content)
          return await until(useObjectUrl(file)).toBeTruthy()
        }
      } else {
        const file = await useDossierStore().loadRemoteFile(entry.content)
        const url = await until(useObjectUrl(file)).toBeTruthy()
        const img = await imageFromUrl(url)
        image.dimensions = [img.width, img.height]
        image.thumbnailUrl = url
        image.lazyUrl = async () => {
          return url
        }
      }

      view.images.push(image as ImageInfo)

      // Images with date are also events
      if (entry.date) {
        view.events.push({
          date: new Date(entry.date),
          granularity: "day",
          title: entry.label,
          type: "image",
          icon: mdiImage,
        })
      }
    }
  }

  for (const section of iterateDossier<Section<"Archive">>(contents, [
    "Archive",
  ])) {
    if (!section.body) {
      continue
    }
    for (const entry of section.body!.entries) {
      if (entry.type === "image") {
        for (const img of entry.files) {
          // Only one file per image usually, but use a loop nonetheless
          view.images.push({
            date: approximateDate(entry.date)?.toISOString(),
            dimensions: [img.meta.width, img.meta.height],
            globalFeatured: false,
            featured: !!(entry as any).featured,
            lazyUrl: async () => {
              const file = await useDossierStore().loadRemoteFile(img)
              return await until(useObjectUrl(file)).toBeTruthy()
            },
            source: entry.source,
            thumbnailUrl: img.meta.thumbnail,
            title: entry.title,
            entities: entry.entities ?? [],
          })
        }

        // Images with date are also events
        if (approximateDate(entry.date)) {
          view.events.push({
            date: approximateDate(entry.date)!,
            granularity: "day",
            title: entry.title,
            type: "image",
            icon: mdiImage,
            important: entry.important,
          })
        }
      }
    }
  }
}

export function collectEventsFromChronology(
  view: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier<Section<"Chronology">>(contents, [
    "Chronology",
  ])) {
    for (const item of arrayToObjectArray<ChronologyTypeX>(
      section.body || [],
      CHRONOLOGY_TYPE_X_FIELDS,
      { important: false },
    )) {
      if (!item.date || !item.title) {
        continue
      }
      view.events.push({
        date: new Date(item.date),
        ...pick(item, ["title", "source"]),
        granularity: "day",
        type: "other",
        icon: mdiCalendar,
      })
    }
  }
}

export function collectReports(view: DossierView, contents: DossierContents) {
  for (const section of iterateDossier<Section<"Text" | "RichText">>(contents, [
    "Report",
    "DarkWeb",
    "MachineLearningTools",
  ])) {
    if (!section.body) {
      continue
    }
    if (section.type === "Text" && !(section.body as string).trim()) {
      continue
    }

    let type: ReportType
    switch (section.meta.role) {
      case "Report":
        type = "general"
        break
      case "DarkWeb":
        type = "darknet"
        break
      case "MachineLearningTools":
        type = "machinelearningtools"
        break
      default:
        type = "other"
    }
    view.reports.push({
      title: section.meta.headline,
      type,
      drawerOrder: section.meta.drawerOrder || 1,
      richContent:
        section.type === "RichText"
          ? (section as Section<"RichText">).body!.content
          : "<p>" + escape((section as Section<"Text">).body) + "</p>",
    })
  }
}

export function collectEventsFromSocialMedia(
  preview: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier<Section<"SocialMedia">>(contents, [
    "SocialMedia",
  ])) {
    for (const event of section.body || []) {
      if (event[0] && event[2]) {
        preview.events.push({
          date: new Date(parseInt(event[2]), 0),
          title: event[1],
          source: event[0],
          icon: mdiAccount,
          granularity: "year",
          type: "social",
        })
      }
    }
  }
}

export function collectEventsFromFiles(
  preview: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier<Section<LabeledFileSectionTypes>>(
    contents,
    ["Documents", "PressClippings"],
  )) {
    for (const entry of section.body || []) {
      if (entry.date && entry.label) {
        preview.events.push({
          date: new Date(entry.date),
          title: entry.label,
          source: entry.source,
          icon: section.meta.role === "PressClippings" ? mdiNewspaper : mdiWeb,
          granularity: "day",
          type: "press",
        })
      }

      if (section.meta.role === "PressClippings") {
        // FIXME The dossierView layer shouldn't know about blocks\
        preview.web_snapshots.push({
          ...omit(entry, ["date"]),
          title: entry.label,
          date: entry.date ? new Date(entry.date) : undefined,
          type: "press",
        })
      }
    }
  }

  for (const section of iterateDossier<Section<"Archive">>(contents, [
    "Archive",
  ])) {
    for (const entry of section.body.entries) {
      let urlWithProtocol: URL | undefined = undefined
      let domainTitle
      if (entry.source) {
        try {
          urlWithProtocol = new URL(withProtocol(entry.source))
        } catch (e) {
          console.log(`Cannot parse URL, ignored: ${e}`)
          continue
        }
        const isKnownDomain = lookupDomain(urlWithProtocol.hostname)

        domainTitle = isKnownDomain
          ? isKnownDomain.name
          : urlWithProtocol.hostname.replace(/^www\./, "")
      }

      const nDate = (entry as any).date
        ? new Date((entry as any).date.replace(/XX-XX/i, "01-01"))
        : undefined
      if (
        [
          "press",
          "website",
          "document",
          "company_db",
          "person_db",
          "post",
        ].includes(entry.type)
      ) {
        preview.web_snapshots.push({
          ...omit(entry),
          title: domainTitle,
          label: entry.title,
          important: entry.important,
          date: nDate,
          type: entry.type,
          content: (entry as any).files[0],
          entities: (entry as any).entities,
        } as WebSnapshotInfo)
        if (entry.featured && !preview.featured_snapshot) {
          preview.featured_snapshot =
            preview.web_snapshots[preview.web_snapshots.length - 1]
        }
      }

      if (entry && nDate && entry.type === "press") {
        preview.events.push({
          entities: (entry as any).entities,
          ...pick(entry, ["title", "source"]),
          date: nDate,
          icon: mdiNewspaper,
          granularity: "year",
          type: "press",
        })
      }
      const isListed = entry.source
        ? useURLParser(entry.source).isListed.value
        : undefined

      if (entry && nDate && entry.type === "website") {
        preview.events.push({
          ...pick(entry, ["title", "source"]),
          entities: (entry as any).entities,
          date: nDate,
          icon: isListed?.type === "company_db" ? mdiWarehouse : mdiCalendar,
          granularity: "year",
          type: isListed?.type === "company_db" ? "company_db" : "website",
        })
      }
      if (entry && nDate && entry.type === "company_db") {
        preview.events.push({
          ...pick(entry, ["title", "source"]),
          entities: (entry as any).entities,
          date: nDate,
          icon: mdiWarehouse,
          granularity: "year",
          type: "company_db",
        })
      }
      if (entry && nDate && entry.type === "person_db") {
        preview.events.push({
          ...pick(entry, ["title", "source"]),
          entities: (entry as any).entities,
          date: nDate,
          icon: mdiAccountFileText,
          granularity: "year",
          type: "person_db",
        })
      }
      if (entry && nDate && entry.type === "document") {
        preview.events.push({
          ...pick(entry, ["title", "source"]),
          entities: (entry as any).entities,
          date: nDate,
          icon: mdiFileDocument,
          granularity: "year",
          type: "document",
        })
      }
    }
  }
}

export function collectEmailAddresses(
  view: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier(contents)) {
    if (section.type !== "KeyValueTable") {
      continue
    }
    for (const entry of section.body as KeyValueType[]) {
      for (const email of entry) {
        if (
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email as string)
        ) {
          view.emails.push(email as string)
        }
      }
    }
  }
  for (const entity of Object.values(view.entities)) {
    if (entity.type === "person") {
      for (const email of entity.emails) {
        if (!view.emails.includes(email.value)) {
          view.emails.push(email.value)
        }
      }
    }
  }
}

export function collectSocialMediaData(
  view: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier<Section<"KeyValueTable">>(contents, [
    "SocialMedia",
  ])) {
    for (const item of arrayToObjectArray<SocialMediaTypeX>(
      section.body || [],
      SOCIAL_MEDIA_TYPE_X_FIELDS,
      { type: "social_media" },
    )) {
      if (!item.url) {
        continue
      }

      let urlWithProtocol: URL | undefined = undefined

      try {
        urlWithProtocol = new URL(withProtocol(item.url))
      } catch (e) {
        console.log(`Cannot parse URL, ignored: ${e}`)
        continue
      }

      const socialMedia = getSocialMediaForDomain(urlWithProtocol.hostname)
      if (socialMedia) {
        view.social_media.push({
          ...pick(item, ["name", "source", "date", "type", "followers"]),
          url: urlWithProtocol.href,
          icon: socialMedia?.icon!,
          color: socialMedia?.color!,
          groupName: socialMedia?.name?.toLowerCase(),
          entities: [],
          type: "social",
        } as SocialMediaTypeX)
      }
    }
  }

  for (const section of iterateDossier<Section<"Archive">>(contents, [
    "Archive",
  ])) {
    for (const entry of section.body.entries) {
      if (!["social", "post"].includes(entry.type) || !entry.source) {
        continue
      }

      let urlWithProtocol: URL | undefined = undefined

      try {
        urlWithProtocol = new URL(withProtocol(entry.source))
      } catch (e) {
        console.log(`Cannot parse URL, ignored: ${e}`)
        continue
      }

      const socialMedia = getSocialMediaForDomain(urlWithProtocol.hostname)

      if (socialMedia) {
        view.social_media.push({
          ...pick(entry, ["source", "followers", "type"]),
          date: approximateDate(entry.date)?.toISOString(),
          name: entry.title,
          url: urlWithProtocol.href,
          icon: socialMedia?.icon,
          color: socialMedia?.color,
          groupName: socialMedia?.name?.toLowerCase(),
          entities: entry.entities || [],
        } as SocialMediaTypeX)
      }

      if (entry.type === "social" && (entry as any).established) {
        view.events.push({
          ...pick(entry, ["title", "source"]),
          entities: entry.entities || [],
          date: new Date((entry as any).established),
          icon: mdiAccountBoxPlusOutline,
          granularity: "year",
          type: "social",
        })
      } else if (entry.type === "post" && entry.date) {
        view.events.push({
          ...pick(entry, ["title", "source"]),
          entities: entry.entities || [],
          date: new Date(entry.date),
          granularity: "day",
          type: "post",
          icon: mdiTextAccount,
        })
      }
    }
  }
}

export function collectLocationsFromPLZ(
  preview: DossierView,
  contents: DossierContents,
) {
  // Find PLZ in text, richtext, and value of keyvalue
  const plz = new Set<string>()
  for (const section of iterateDossier(contents)) {
    if (!section.body) {
      continue
    }
    if (section.type === "Text") {
      for (const match of (section as Section<"Text">).body!.matchAll(PLZ_RE)) {
        plz.add(match[0])
      }
    } else if (section.type === "RichText") {
      for (const match of (section as Section<"RichText">)
        .body!.content.replace(/(<([^>]+?)>)/gi, "")
        .matchAll(PLZ_RE)) {
        plz.add(match[0])
      }
    } else if (
      ["KeyValueTable", "SocialMedia", "Chronology"].includes(section.type)
    ) {
      for (const line of (
        section as Section<"KeyValueTable" | "SocialMedia" | "Chronology">
      ).body!) {
        for (const match of line[1].matchAll(PLZ_RE)) {
          plz.add(match[0])
        }
      }
    }
  }

  for (const p of plz) {
    const entry = lookupGermanPlz(p)
    if (!entry?.name) {
      continue
    }
    preview.locations.push({
      pos: [parseFloat(entry.lat), parseFloat(entry.lon)],
      size: 23,
      type: "other",
      icon: mdiMapMarker,
      address: `${entry.plz} ${entry.name}`,
      entities: [],
    })
  }
}

export function collectLocationsFromEntities(
  view: DossierView,
  contents: DossierContents,
) {
  for (const entity of Object.values(view.entities)) {
    for (const address of entity.addresses || []) {
      const plzs = new Set<string>()
      for (const match of address.value.address.matchAll(PLZ_RE)) {
        plzs.add(match[0])
      }
      for (const p of plzs) {
        const entry = lookupGermanPlz(p)
        if (!entry) {
          continue
        }
        view.locations.push({
          entities: [entity.id],
          type: entity.type,
          pos: [parseFloat(entry.lat), parseFloat(entry.lon)],
          size: 23,
          label: address.value.label,
          title: entity.title,
          address: `${entry.plz} ${entry.name}`,
        })
      }
    }
  }
}

export function collectMetricData(
  preview: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier(contents, ["Chart"])) {
    if (section.type === "KpiBarChart") {
      const metricEntry: MetricInfo = {
        type: "kpi",
        title: (section as Section<"KpiBarChart">).meta.headline,
        data: (section as Section<"KpiBarChart">)
          .body!.filter((entry) => entry[1] !== null && entry[1] !== undefined)
          .map((entry) => ({
            dimension: entry[0],
            value: entry[1],
            levels: arrayToObjectArray<{ value: number; label: string }>(
              entry[2] || _DEFAULT_KPI_BAR_CHART_LEVELS,
              ["value", "label"],
            ),
          })),
      }
      if (metricEntry.data.length) {
        preview.metrics.push(metricEntry)
      }
    }
  }
}

export function extractEntityInformation(
  view: DossierView,
  contents: DossierContents,
) {
  const data = {} as Record<EntityDataEntityIdentifier, EntityDataInfo>

  for (const key of ["person", "company"] as const) {
    for (const name of contents.meta[key] ?? []) {
      const id = `${key}/${name}` as EntityDataEntityIdentifier
      const baseProperties = {
        type: key,
        id,
        title: name,
        otherInformation: {},
      }

      // Initialize basic properties based on type. Need to extend here if type is extended
      // Specific contents are added later
      if (key === "person") {
        data[id] = {
          ...baseProperties,
          emails: [],
          phone_numbers: [],
          aliases: [],
          relatedPersons: [],
          relatedCompanies: {},
        } as EntityDataInfo
      } else if (key === "company") {
        data[id] = {
          ...baseProperties,
          relatedCompanies: [],
          relatedPersons: {},
        } as EntityDataInfo
      }
    }
  }

  for (const section of iterateDossier<Section<"NetworkAnalysis">>(contents, [
    "NetworkAnalysis",
  ])) {
    view.predefinedNetworkLayout = section.body.layout

    for (const [person_a, relations] of Object.entries(
      section.body.person_person,
    )) {
      for (const [person_b, related] of Object.entries(relations)) {
        if (
          related &&
          data[`person/${person_a}`] &&
          data[`person/${person_b}`]
        ) {
          ;(
            (data[`person/${person_a}`].relatedPersons ??=
              []) as Array<EntityDataEntityIdentifier>
          ).push(`person/${person_b}`)
          ;(
            (data[`person/${person_b}`].relatedPersons ??=
              []) as Array<EntityDataEntityIdentifier>
          ).push(`person/${person_a}`)
        }
      }
    }
    for (const [company_a, relations] of Object.entries(
      section.body.company_company,
    )) {
      for (const [company_b, related] of Object.entries(relations)) {
        if (
          related &&
          data[`company/${company_a}`] &&
          data[`company/${company_b}`]
        ) {
          ;(
            (data[`company/${company_a}`].relatedCompanies ??=
              []) as Array<EntityDataEntityIdentifier>
          ).push(`company/${company_b}`)
          ;(
            (data[`company/${company_b}`].relatedCompanies ??=
              []) as Array<EntityDataEntityIdentifier>
          ).push(`company/${company_a}`)
        }
      }
    }
    for (const [person_a, relations] of Object.entries(
      section.body.person_company,
    )) {
      for (const [company_b, role] of Object.entries(relations)) {
        if (role) {
          if (!data[`person/${person_a}`] || !data[`company/${company_b}`]) {
            continue
          }

          ;(data[`person/${person_a}`] as any).relatedCompanies[
            `company/${company_b}`
          ] = role
          ;(data[`company/${company_b}`] as any).relatedPersons[
            `person/${person_a}`
          ] = role
        }
      }
    }
  }

  for (const section of iterateDossier<Section<"EntityData">>(contents, [
    "EntityData",
  ])) {
    const target = data[section.body.entity]
    if (!target) {
      continue
    }
    for (const entry of section.body.entries) {
      if (!entry.value) {
        continue
      }
      if (target.type === "person") {
        extractPersonEntityData(view, target!, entry)
      } else if (target.type === "company") {
        extractCompanyEntityData(view, target!, entry)
      }
    }
    if (["person", "company"].includes(target.type)) {
      if (target.title) {
        target.initial = generateInitials(target.title)
      }
    }
  }

  for (const image of view.images) {
    if (!image.featured) {
      continue
    }
    for (const entityId of image.entities) {
      const target = data[entityId]
      if (!target || target.featuredImage) {
        continue
      }
      target.featuredImage = image
    }
  }

  view.entities = data
}

function generateInitials(title: string) {
  const parts = title.split(/\W|[0-9]/).filter((item) => item.length > 1)
  return parts.map((item) => item.substring(0, 1).toUpperCase()).join("")
}

function addAddress(
  target: EntityDataInfo,
  label: string,
  entry: EntityDataRow,
) {
  ;(target.addresses ??= []).push({
    value: { address: entry.value, label },
    source: entry.source,
  })
}

// FIXME Enhance for granularity
function parseYearOrDate(value: string): YearOrDate {
  const d: DateStruct | null = parseDate(value)
  if (d?.month == "X") {
    return d!.year
  }
  if (d) {
    return new Date(
      d.year,
      (d as DateStruct).month == "X" ? 0 : d.month - 1,
      d.day == "X" ? 1 : (d.day as number),
    )
  }
  return new Date()
}

function extractPersonEntityData(
  view: DossierView,
  target: EntityDataInfo & { type: "person" },
  entry: EntityDataRow,
) {
  const canonicalKey = entry.key.replace(" ", "").toLowerCase()
  switch (canonicalKey) {
    case "vor-/nachname":
      target.title = entry.value
      break
    case "e-mail":
      target.emails.push(entry)
      break
    case "aliasnamen":
      target.aliases.push(entry)
      break
    case "telefon":
      target.phone_numbers.push(entry)
      break
    case "geburtsdatum":
      target.birthDate = {
        source: entry.source,
        value: parseYearOrDate(entry.value),
      }
      break
    case "geburtsort":
      addAddress(target, "Geburtsort", entry)
      break
    case "adresse":
      addAddress(target, "Adresse", entry)
      break
    default:
      ;(target.otherInformation[entry.key] ??= []).push(entry)
      break
  }
}

function extractCompanyEntityData(
  view: DossierView,
  target: EntityDataInfo & { type: "company" },
  entry: EntityDataRow,
) {
  const canonicalKey = entry.key.replace(" ", "").replace("-", "").toLowerCase()
  switch (canonicalKey) {
    case "firmenname":
      target.title = entry.value
      break
    case "geschäftssitz":
      addAddress(target, "Geschäftssitz", entry)
      break
    case "handelsregister":
    case "handelsregisternummer":
    case "handelsregisternr":
      target.register_id = entry.value
      break
    case "tätigkeit":
    case "gegenstand":
      target.purpose = entry.value
      break
    case "adresse":
      addAddress(target, "Adresse", entry)
      break
    case "gründungsdatum":
      target.foundingDate = {
        source: entry.source,
        value: parseYearOrDate(entry.value),
      }
      break
    default:
      ;(target.otherInformation[entry.key] ??= []).push(entry)
      break
  }
}

export function collectWidgetsData(
  view: DossierView,
  contents: DossierContents,
) {
  for (const section of iterateDossier<Section<"ExternalWidgets">>(contents, [
    "ExternalWidgets",
  ])) {
    if (section.body?.widgets?.length) {
      for (const entry of section.body.widgets) {
        if (entry.type === "google_trends") {
          if (!entry.code || entry.code.trim() === "") {
            continue
          }
          if (!entry?.entities?.length) {
            view.widgets.push(entry)
          } else {
            for (const ent of entry.entities) {
              if (view.entities[ent]) {
                view.entities[ent].widgets
                  ? view.entities[ent].widgets?.push(entry)
                  : (view.entities[ent].widgets = [entry])
              }
            }
          }
        }
      }
    }
  }
}
